@font-face {
  font-family: "NeueRational";
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/NeueRationalCondensedTest-BlackItalic.otf");
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background: #b9dc85;
  background: linear-gradient(
    0deg,
    rgba(139, 190, 119, 1) 0%,
    rgba(185, 220, 133, 1) 50%,
    rgba(185, 220, 133, 1) 50%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 1) 100%
  );
  background-size: 100% calc(100% + 100vh);
  background-position-y: -100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
  svg {
    height: 100%;
  }
}
.node text {
  pointer-events: none;
  font: 10px sans-serif;
}

#canvas {
  display: block;
}
